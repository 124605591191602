import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Field,
  Control,
  StaticButton,
  Button,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Roman Numbers Converter",
    link: "/roman-number-converter/",
  },
]
const seeAlsoArray = [
  "/morse-code-conversion",
  "/ascii-and-binary-converter",
  "/ascii-code-converter",
  "/binary-decoder",
  "/binary-encoder",
]

function RomanNumber() {
  let [inputroman, setinputroman] = useState("")
  let [inputnumber, setinputnumber] = useState("")

  let [resultHere, setresultHere] = useState("")
  let [errorHere, seterrorHere] = useState("")

  function inputromanC(e) {
    setinputroman(e.target.value)
  }
  function inputnumberC(e) {
    setinputnumber(e.target.value)
  }

  function encode() {
    var number = Number(inputroman)
    if (number > 4999) {
      seterrorHere("Error: Maximum number is 4999")
      setsecondResult("")
    } else {
      if (number > 0 || number % 1 !== 0) {
        var romanSymbols = {
          M: 1000,
          CM: 900,
          D: 500,
          CD: 400,
          C: 100,
          XC: 90,
          L: 50,
          XL: 40,
          X: 10,
          IX: 9,
          V: 5,
          IV: 4,
          I: 1,
        }
        var symbolValue = void 0,
          romanNumber = ""
        for (symbolValue in romanSymbols) {
          while (number >= romanSymbols[symbolValue]) {
            romanNumber += symbolValue
            number -= romanSymbols[symbolValue]
          }
        }
        setresultHere(romanNumber)
        seterrorHere("")
      } else {
        seterrorHere(
          "Error: number smaller than 0 float number is not allowed."
        )
        setresultHere("")
      }
    }
  }

  let [secondResult, setsecondResult] = useState("")
  let [secondError, setsecondError] = useState("")
  function decode() {
    var romanNumber = inputnumber
    if (!/[^MDCLXVI]/g.test(romanNumber)) {
      var stringArray = romanNumber.split("").reverse()
      var romanSymbols_1 = {
        I: 1,
        V: 5,
        X: 10,
        L: 50,
        C: 100,
        D: 500,
        M: 1000,
      }
      var decodedArray = stringArray.map(function (symbol) {
        return romanSymbols_1[symbol]
      })
      var decodedArrayLength = decodedArray.length
      var i = 0,
        maxValue = 0,
        totalValue = 0
      for (; i < decodedArrayLength; i++) {
        if (decodedArray[i] >= maxValue) {
          maxValue = decodedArray[i]
          totalValue += decodedArray[i]
        } else {
          totalValue = totalValue - decodedArray[i]
        }
      }
      setsecondResult(totalValue)
      setsecondError("")
    } else {
      setsecondError("Error: Inputed number is not a roman number.")
      setsecondResult("")
    }
  }
  return (
    <div>
      <Field>
        <Control>
          <StaticButton>Input Number</StaticButton>
        </Control>
        <Control className="expandedWidth">
          <Input
            radius="0"
            type="number"
            className="borderedRight"
            value={inputroman}
            onChange={inputromanC}
          />
        </Control>
      </Field>
      <Button
        borderColor="#1da1f2"
        color="#1da1f2"
        hoverColor="white"
        hoverBorderColor="#1da1f2"
        hoverBackgroundColor="#1da1f2"
        onClick={encode}
      >
        To Roman Numerals
      </Button>
      <p className="h5 mt-3">{resultHere}</p>
      <p className="h5 mt-3">{errorHere}</p>
      <Field>
        <Control>
          <StaticButton>Roman Numerals</StaticButton>
        </Control>
        <Control className="expandedWidth">
          <Input
            radius="0"
            className="borderedRight"
            type="text"
            value={inputnumber}
            onChange={inputnumberC}
          />
        </Control>
      </Field>
      <Button
        borderColor="#1da1f2"
        color="white"
        backgroundColor="#1da1f2"
        hoverColor="#1da1f2"
        hoverBorderColor="#1da1f2"
        onClick={decode}
      >
        To Numbers
      </Button>
      <p className="h5 mt-3">{secondResult}</p>
      <p className="h5 mt-3">{secondError}</p>
    </div>
  )
}

function RomanNumbersConverter(props) {
  return (
    <Layout location={props.location}>
      <SEO
        title="Roman Numerals Conversion"
        description="Roman Numerals, Roman Numerals Conversion, Simple Converter Roman Numerals, step by step input Number to convert to roman number by click on convert to roman numbers or input roman numerals to convert to a number."
        keywords={[
          "roman Numerals, roman number, Roman Numerals Conversion,number to Roman Numerals, Roman Numerals to number, Roman Number Converter,Roman Number translator,Roman Number, Roman Numerals translator,convert Roman Number, Roman Number convertion, Roman Numerals converter,list of roman Numerals,roman Numerals conversion,roman numerals encoder decoder.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Roman Numbers Converter</Title>
        <SubTitle>Roman Numerals Conversion</SubTitle>
        <Columns>
          <Column>
            <RomanNumber />
          </Column>
          <Column>
            {" "}
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg
                filename="roman.png"
                alt="roman number, convert roman numbers"
              />
            </FlexDiv>
          </Column>
        </Columns>

        <br />
        <h3>Roman Numbers</h3>
        <p>
          Roman numerals are a numerical system used by the Romans. They are an
          additive system in which letters are used to denote certain "base"
          numbers, and arbitrary numbers are then denoted using combinations of
          symbols.
        </p>
        <br />
        <Columns>
          <Column>
            <Table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Roman Numerals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>I</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>II</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>III</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>IV</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>V</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>VI</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>VII</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>VIII</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>IX</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>XI</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>XII</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>XIII</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>XIV</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>XV</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>XVI</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>XVII</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>XVIII</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>XIX</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>XX</td>
                </tr>
                <tr>
                  <td>21</td>
                  <td>XXI</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>XXII</td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>XXIII</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>XXIV</td>
                </tr>
                <tr>
                  <td>25</td>
                  <td>XXV</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>XXVI</td>
                </tr>
                <tr>
                  <td>27</td>
                  <td>XXVII</td>
                </tr>
                <tr>
                  <td>28</td>
                  <td>XXVIII</td>
                </tr>
                <tr>
                  <td>29</td>
                  <td>XXIX</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td>XXX</td>
                </tr>
                <tr>
                  <td>31</td>
                  <td>XXXI</td>
                </tr>
                <tr>
                  <td>32</td>
                  <td>XXXII</td>
                </tr>
                <tr>
                  <td>33</td>
                  <td>XXXIII</td>
                </tr>
                <tr>
                  <td>34</td>
                  <td>XXXIV</td>
                </tr>
                <tr>
                  <td>35</td>
                  <td>XXXV</td>
                </tr>
                <tr>
                  <td>36</td>
                  <td>XXXVI</td>
                </tr>
                <tr>
                  <td>37</td>
                  <td>XXXVII</td>
                </tr>
                <tr>
                  <td>38</td>
                  <td>XXXVIII</td>
                </tr>
                <tr>
                  <td>39</td>
                  <td>XXXIX</td>
                </tr>
                <tr>
                  <td>40</td>
                  <td>XL</td>
                </tr>
                <tr>
                  <td>41</td>
                  <td>XLI</td>
                </tr>
                <tr>
                  <td>42</td>
                  <td>XLII</td>
                </tr>
                <tr>
                  <td>43</td>
                  <td>XLIII</td>
                </tr>
                <tr>
                  <td>44</td>
                  <td>XLIV</td>
                </tr>
                <tr>
                  <td>45</td>
                  <td>XLV</td>
                </tr>
                <tr>
                  <td>46</td>
                  <td>XLVI</td>
                </tr>
                <tr>
                  <td>47</td>
                  <td>XLVII</td>
                </tr>
                <tr>
                  <td>48</td>
                  <td>XLVIII</td>
                </tr>
                <tr>
                  <td>49</td>
                  <td>XLIX</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>L</td>
                </tr>
              </tbody>
            </Table>
          </Column>
          <Column>
            <Table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Roman Numerals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>51</td>
                  <td>LI</td>
                </tr>
                <tr>
                  <td>52</td>
                  <td>LII</td>
                </tr>
                <tr>
                  <td>53</td>
                  <td>LIII</td>
                </tr>
                <tr>
                  <td>54</td>
                  <td>LIV</td>
                </tr>
                <tr>
                  <td>55</td>
                  <td>LV</td>
                </tr>
                <tr>
                  <td>56</td>
                  <td>LVI</td>
                </tr>
                <tr>
                  <td>57</td>
                  <td>LVII</td>
                </tr>
                <tr>
                  <td>58</td>
                  <td>LVIII</td>
                </tr>
                <tr>
                  <td>59</td>
                  <td>LIX</td>
                </tr>
                <tr>
                  <td>60</td>
                  <td>LX</td>
                </tr>
                <tr>
                  <td>61</td>
                  <td>LXI</td>
                </tr>
                <tr>
                  <td>62</td>
                  <td>LXII</td>
                </tr>
                <tr>
                  <td>63</td>
                  <td>LXIII</td>
                </tr>
                <tr>
                  <td>64</td>
                  <td>LXIV</td>
                </tr>
                <tr>
                  <td>65</td>
                  <td>LXV</td>
                </tr>
                <tr>
                  <td>66</td>
                  <td>LXVI</td>
                </tr>
                <tr>
                  <td>67</td>
                  <td>LXVII</td>
                </tr>
                <tr>
                  <td>68</td>
                  <td>LXVIII</td>
                </tr>
                <tr>
                  <td>69</td>
                  <td>LXIX</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>LXX</td>
                </tr>
                <tr>
                  <td>71</td>
                  <td>LXXI</td>
                </tr>
                <tr>
                  <td>72</td>
                  <td>LXXII</td>
                </tr>
                <tr>
                  <td>73</td>
                  <td>LXXIII</td>
                </tr>
                <tr>
                  <td>74</td>
                  <td>LXXIV</td>
                </tr>
                <tr>
                  <td>75</td>
                  <td>LXXV</td>
                </tr>
                <tr>
                  <td>76</td>
                  <td>LXXVI</td>
                </tr>
                <tr>
                  <td>77</td>
                  <td>LXXVII</td>
                </tr>
                <tr>
                  <td>78</td>
                  <td>LXXVIII</td>
                </tr>
                <tr>
                  <td>79</td>
                  <td>LXXIX</td>
                </tr>
                <tr>
                  <td>80</td>
                  <td>LXXX</td>
                </tr>
                <tr>
                  <td>81</td>
                  <td>LXXXI</td>
                </tr>
                <tr>
                  <td>82</td>
                  <td>LXXXII</td>
                </tr>
                <tr>
                  <td>83</td>
                  <td>LXXXIII</td>
                </tr>
                <tr>
                  <td>84</td>
                  <td>LXXXIV</td>
                </tr>
                <tr>
                  <td>85</td>
                  <td>LXXXV</td>
                </tr>
                <tr>
                  <td>86</td>
                  <td>LXXXVI</td>
                </tr>
                <tr>
                  <td>87</td>
                  <td>LXXXVII</td>
                </tr>
                <tr>
                  <td>88</td>
                  <td>LXXXVIII</td>
                </tr>
                <tr>
                  <td>89</td>
                  <td>LXXXIX</td>
                </tr>
                <tr>
                  <td>90</td>
                  <td>XC</td>
                </tr>
                <tr>
                  <td>91</td>
                  <td>XCI</td>
                </tr>
                <tr>
                  <td>92</td>
                  <td>XCII</td>
                </tr>
                <tr>
                  <td>93</td>
                  <td>XCIII</td>
                </tr>
                <tr>
                  <td>94</td>
                  <td>XCIV</td>
                </tr>
                <tr>
                  <td>95</td>
                  <td>XCV</td>
                </tr>
                <tr>
                  <td>96</td>
                  <td>XCVI</td>
                </tr>
                <tr>
                  <td>97</td>
                  <td>XCVII</td>
                </tr>
                <tr>
                  <td>98</td>
                  <td>XCVIII</td>
                </tr>
                <tr>
                  <td>99</td>
                  <td>XCIX</td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>C</td>
                </tr>
              </tbody>
            </Table>
          </Column>
          <Column>
            <Table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Roman Numerals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>101</td>
                  <td>CI</td>
                </tr>
                <tr>
                  <td>102</td>
                  <td>CII</td>
                </tr>
                <tr>
                  <td>103</td>
                  <td>CIII</td>
                </tr>
                <tr>
                  <td>104</td>
                  <td>CIV</td>
                </tr>
                <tr>
                  <td>105</td>
                  <td>CV</td>
                </tr>
                <tr>
                  <td>106</td>
                  <td>CVI</td>
                </tr>
                <tr>
                  <td>107</td>
                  <td>CVII</td>
                </tr>
                <tr>
                  <td>108</td>
                  <td>CVIII</td>
                </tr>
                <tr>
                  <td>109</td>
                  <td>CIX</td>
                </tr>
                <tr>
                  <td>110</td>
                  <td>CX</td>
                </tr>
                <tr>
                  <td>111</td>
                  <td>CXI</td>
                </tr>
                <tr>
                  <td>112</td>
                  <td>CXII</td>
                </tr>
                <tr>
                  <td>113</td>
                  <td>CXIII</td>
                </tr>
                <tr>
                  <td>114</td>
                  <td>CXIV</td>
                </tr>
                <tr>
                  <td>115</td>
                  <td>CXV</td>
                </tr>
                <tr>
                  <td>116</td>
                  <td>CXVI</td>
                </tr>
                <tr>
                  <td>117</td>
                  <td>CXVII</td>
                </tr>
                <tr>
                  <td>118</td>
                  <td>CXVIII</td>
                </tr>
                <tr>
                  <td>119</td>
                  <td>CXIX</td>
                </tr>
                <tr>
                  <td>120</td>
                  <td>CXX</td>
                </tr>
                <tr>
                  <td>121</td>
                  <td>CXXI</td>
                </tr>
                <tr>
                  <td>122</td>
                  <td>CXXII</td>
                </tr>
                <tr>
                  <td>123</td>
                  <td>CXXIII</td>
                </tr>
                <tr>
                  <td>124</td>
                  <td>CXXIV</td>
                </tr>
                <tr>
                  <td>125</td>
                  <td>CXXV</td>
                </tr>
                <tr>
                  <td>126</td>
                  <td>CXXVI</td>
                </tr>
                <tr>
                  <td>127</td>
                  <td>CXXVII</td>
                </tr>
                <tr>
                  <td>128</td>
                  <td>CXXVIII</td>
                </tr>
                <tr>
                  <td>129</td>
                  <td>CXXIX</td>
                </tr>
                <tr>
                  <td>130</td>
                  <td>CXXX</td>
                </tr>
                <tr>
                  <td>131</td>
                  <td>CXXXI</td>
                </tr>
                <tr>
                  <td>132</td>
                  <td>CXXXII</td>
                </tr>
                <tr>
                  <td>133</td>
                  <td>CXXXIII</td>
                </tr>
                <tr>
                  <td>134</td>
                  <td>CXXXIV</td>
                </tr>
                <tr>
                  <td>135</td>
                  <td>CXXXV</td>
                </tr>
                <tr>
                  <td>136</td>
                  <td>CXXXVI</td>
                </tr>
                <tr>
                  <td>137</td>
                  <td>CXXXVII</td>
                </tr>
                <tr>
                  <td>138</td>
                  <td>CXXXVIII</td>
                </tr>
                <tr>
                  <td>139</td>
                  <td>CXXXIX</td>
                </tr>
                <tr>
                  <td>140</td>
                  <td>CXL</td>
                </tr>
                <tr>
                  <td>141</td>
                  <td>CXLI</td>
                </tr>
                <tr>
                  <td>142</td>
                  <td>CXLII</td>
                </tr>
                <tr>
                  <td>143</td>
                  <td>CXLIII</td>
                </tr>
                <tr>
                  <td>144</td>
                  <td>CXLIV</td>
                </tr>
                <tr>
                  <td>145</td>
                  <td>CXLV</td>
                </tr>
                <tr>
                  <td>146</td>
                  <td>CXLVI</td>
                </tr>
                <tr>
                  <td>147</td>
                  <td>CXLVII</td>
                </tr>
                <tr>
                  <td>148</td>
                  <td>CXLVIII</td>
                </tr>
                <tr>
                  <td>149</td>
                  <td>CXLIX</td>
                </tr>
                <tr>
                  <td>150</td>
                  <td>CL</td>
                </tr>
              </tbody>
            </Table>
          </Column>
        </Columns>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default RomanNumbersConverter
